<template>
    <section class="location" id="sectionLocation">
        <div class="location__inner">
            <div class="location__map-wrap" >
                <img src="/pictures/location/main-map.jpg" alt="Budapest, Eötvös út 41, 1121" class="w-100 d-none d-md-block img--desctop" />
                <img src="/pictures/location/main-map-mobile.jpg" alt="Budapest, Eötvös út 41, 1121" class="w-100 d-md-none img--mobile" />
                <div class="h-100 location__marker-wrap " >
                    <div class="location__marker animateScroll" v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'boundeDown')">
                        <a href="https://www.google.com/maps/place/Budapest,+E%C3%B6tv%C3%B6s+%C3%BAt+41,+1121/@47.4995747,18.9723159,17.26z/data=!4m5!3m4!1s0x4741defa557e98e1:0x421ff4455b5493b3!8m2!3d47.4995939!4d18.9741484" target="_blank"><img src="@/assets/images/icons/ic-map-marker-black.svg" /></a>
                    </div>
                </div>
            </div>
            <div class="location__content" style="pointer-events: none">
                <b-container fluid="xl" >
                    <b-row>
                        <b-col cols="12" lg="6"></b-col>
                        <b-col cols="12" lg="6" class="location__text-wrap-outer h-100">
                            <div class="location__text-wrap" style="pointer-events: visiblePainted">
                                <h2 class="animateScroll" v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up')">{{tr('location_title')}}</h2>
                                
                                <div class="location__data animateScroll" v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up', 'delay-1')">
                                    <a style="color: inherit" href="https://www.google.com/maps/place/Budapest,+E%C3%B6tv%C3%B6s+%C3%BAt+41,+1121/@47.4995747,18.9723159,17.26z/data=!4m5!3m4!1s0x4741defa557e98e1:0x421ff4455b5493b3!8m2!3d47.4995939!4d18.9741484" target="_blank" v-html="tr('location_address')"></a>
                                </div>
                                

                                <div v-if="!isPage" class="location__text animateScroll" v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up', 'delay-2')">
                                    {{tr('location_text_1')}}
                                </div>
                                <div v-if="isPage" class="animateScroll location__page-text" v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up', 'delay-3')">
                                   {{tr('location_text_2')}}
                                </div>
                                <router-link :to="{name: 'location'}" class="btn btn--black btn--arrow animateScroll" v-if="!isPage" type="button " v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up', 'delay-3')"><span>{{tr('btn_learn_more')}}</span></router-link>
                                <div class="border-line animateScroll" v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'border-anim', 'delay-4')"></div>
                            </div>
                        </b-col>
                    
                    </b-row>                                            
                </b-container>
            </div>
        </div>
        <div class="animateScroll bgr-img bgr-img--home" v-if="!isPage" v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up', 'delay-3')">
            <img src="@/assets/images/bgr/luminor-location-mobil.jpg" />
        </div>
         <div class="animateScroll bgr-img bgr-img--page" v-else v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up', 'delay-3')">
            <img src="@/assets/images/bgr/luminor-location-2-mobil.jpg" />
        </div>
    </section>
</template>

<script>

export default ({
    props:['isPage'],
    data(){
        return{
           
        }
    },    
})
</script>
